import request from '@/util/request'

// 退货统计接口  czy
export function orders(data){
    return request({
        url: '/tui-huo-dan/orders',
        method: 'post',
        data
    })
}


//基础列表
export function list(data) {
    return request({
        url: '/tui-huo-dan/list',
        method: 'post',
        data
    })
}
// 仓库信息多选列表
export function getCangKuList() {
    return request({
        url: '/cang-ku/get-cang-ku-list',
        method: 'post',
    })
}


//添加保存
export function add(data) {
    return request({
        url: '/tui-huo-dan/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/tui-huo-dan/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/tui-huo-dan/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/tui-huo-dan/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/tui-huo-dan/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/tui-huo-dan/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/tui-huo-dan/query',
        method: 'post',
        data
    })
}

/*
列表路由
{
    path: 'tui-huo-dan-list',
    component: () => import('@/view/cggl/cgthgl/TuiHuoDanList'),
    name: 'TuiHuoDanList',
    meta: {title: '退货单', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'tui-huo-dan-query',
    component: () => import('@/view/cggl/cgthgl/TuiHuoDanQuery'),
    name: 'TuiHuoDanQuery',
    meta: {title: '退货单查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'tui-huo-dan-remind-list',
    component: () => import('@/view/cggl/cgthgl/TuiHuoDanRemindList'),
    name: 'TuiHuoDanRemindList',
    meta: {title: '退货单提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"退货单基础列表","methodUrl":"/tui-huo-dan/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"退货单添加保存","methodUrl":"/tui-huo-dan/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"退货单获取编辑数据","methodUrl":"/tui-huo-dan/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"退货单获取详情数据","methodUrl":"/tui-huo-dan/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"退货单编辑保存","methodUrl":"/tui-huo-dan/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"退货单删除","methodUrl":"/tui-huo-dan/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"退货单批量删除","methodUrl":"/tui-huo-dan/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"退货单独立查询","methodUrl":"/tui-huo-dan/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
