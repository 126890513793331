<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="tuiHuoDanID" label="退货单ID" />
<!--            <x-detail-item prop="caiGouDanID" label="采购单ID" />-->
            <x-detail-item prop="caoZuoRenXingMing" label="操作人姓名" />
            <x-detail-item prop="caoZuoRenID" label="操作人ID" />
            <x-detail-item prop="tuiHuoSJ" label="退货时间" />
            <x-detail-item prop="gongYingShang" label="供应商" />
            <x-detail-item prop="cangKuMC" label="仓库名称" />
            <x-detail-item prop="yuanCaiGouJinE" label="原采购金额" />
            <x-detail-item prop="tuiHuoJinE" label="退货金额" />
            <x-detail-item prop="yingKuiJinE" label="盈亏金额" />
            <x-detail-item prop="heJiZhong" label="合计（种）" />
            <x-detail-item prop="tuiHuoYuanYin" label="退货原因备注" />
        </x-detail>
        <x-divider title="退货的详情"></x-divider>
        <el-table :data="entity.tuiHuoDeXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="tuiHuoDanID" label="退货单ID" />
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="shangPinID" label="商品ID" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="150" prop="baoZhiQi" label="保质期" />
            <el-table-column width="150" prop="chengBenJia" label="原采购价" />
            <el-table-column width="150" prop="tuiHuoSL" label="退货数量" />
            <el-table-column width="150" prop="chuKuJia" label="退货价" />
            <el-table-column width="150" prop="xiaoJi" label="小计" />
            <el-table-column width="150" prop="tuiHuoYuanYin" label="退货原因" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/cggl/TuiHuoDan";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                tuiHuoDanID: "", // 退货单ID
                caiGouDanID: "", // 采购单ID
                caoZuoRenXingMing: "", // 操作人姓名
                caoZuoRenID: "", // 操作人ID
                tuiHuoSJ: "", // 退货时间
                gongYingShang: "", // 供应商
                cangKuMC: "", // 仓库名称
                yuanCaiGouJinE: "", // 原采购金额
                tuiHuoJinE: "", // 退货金额
                yingKuiJinE: "", // 盈亏金额
                heJiZhong: "", // 合计（种）
                tuiHuoYuanYin: "", // 退货原因备注
                tuiHuoDeXiangQings: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>
